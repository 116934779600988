var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"linkHref") || (depth0 != null ? lookupProperty(depth0,"linkHref") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"linkHref","hash":{},"data":data,"loc":{"start":{"line":1,"column":25},"end":{"line":1,"column":37}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optTarget") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":38},"end":{"line":1,"column":158}}})) != null ? stack1 : "")
    + " class=\"ws10-prevent-decoration ws10-image-link\">";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " target=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optTarget") || (depth0 != null ? lookupProperty(depth0,"optTarget") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"optTarget","hash":{},"data":data,"loc":{"start":{"line":1,"column":64},"end":{"line":1,"column":77}}}) : helper)))
    + "\""
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optTarget") : depth0),"===","_blank",{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":78},"end":{"line":1,"column":151}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return " rel=\"noopener noreferrer\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "full";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"objectFit") || (depth0 != null ? lookupProperty(depth0,"objectFit") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"objectFit","hash":{},"data":data,"loc":{"start":{"line":4,"column":60},"end":{"line":4,"column":73}}}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    return "none";
},"11":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkHref") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":214}}})) != null ? stack1 : "")
    + "\n    <picture class=\"ws10-image ws10-image--width-"
    + alias4(((helper = (helper = lookupProperty(helpers,"optWidth") || (depth0 != null ? lookupProperty(depth0,"optWidth") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optWidth","hash":{},"data":data,"loc":{"start":{"line":2,"column":49},"end":{"line":2,"column":61}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"optWidth") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":61},"end":{"line":2,"column":96}}})) != null ? stack1 : "")
    + "\">\n        <source srcset=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imgSrcMobile") || (depth0 != null ? lookupProperty(depth0,"imgSrcMobile") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imgSrcMobile","hash":{},"data":data,"loc":{"start":{"line":3,"column":24},"end":{"line":3,"column":40}}}) : helper)))
    + "\" media=\"(max-width: 599px)\">\n        <img class=\"ws10-image-object-fit--"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"objectFit") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":4,"column":43},"end":{"line":4,"column":92}}})) != null ? stack1 : "")
    + "\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imgSrcDesktop") || (depth0 != null ? lookupProperty(depth0,"imgSrcDesktop") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imgSrcDesktop","hash":{},"data":data,"loc":{"start":{"line":4,"column":99},"end":{"line":4,"column":116}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdAltText") || (depth0 != null ? lookupProperty(depth0,"stdAltText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdAltText","hash":{},"data":data,"loc":{"start":{"line":4,"column":123},"end":{"line":4,"column":137}}}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdTitle") || (depth0 != null ? lookupProperty(depth0,"stdTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdTitle","hash":{},"data":data,"loc":{"start":{"line":4,"column":146},"end":{"line":4,"column":158}}}) : helper)))
    + "\">\n    </picture>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkHref") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":6,"column":27}}})) != null ? stack1 : "")
    + "\n";
},"useData":true});