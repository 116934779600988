/**
 * Base class name
 */
export var FILTER_PILLS_BASE_CLASSNAME = 'ws10-filter-pill';
/**
 * class and state constant for the container being centered
 */
export var ITEM_CONTAINER_ALIGN_VALUE_CENTER = 'center';
/**
 * class and state constant for the container being right aligned
 */
export var ITEM_CONTAINER_ALIGN_VALUE_RIGHT = 'right';
/**
 * list of items that can be used as a filterpill container's alignment
 */
export var ITEM_CONTAINER_ALIGN_VALUES = [
    ITEM_CONTAINER_ALIGN_VALUE_CENTER,
    ITEM_CONTAINER_ALIGN_VALUE_RIGHT,
];
/**
 * constant for filter pill item being checked
 */
export var FILTER_PILL_ITEM_STATE_CHECKED = 'checked';
/**
 * constant for filter pill item being disabled
 */
export var FILTER_PILL_ITEM_STATE_DISABLED = 'disabled';
/**
 * Item can be checked and disabled (means, selection cannot be undone)
 */
export var FILTER_PILL_ITEM_STATE_CHECKED_DISABLED = 'checked-disabled';
/**
 * list of items that can be used as a single filterpill item's state
 */
export var ITEM_STATE_VALUES = [
    FILTER_PILL_ITEM_STATE_CHECKED,
    FILTER_PILL_ITEM_STATE_DISABLED,
    FILTER_PILL_ITEM_STATE_CHECKED_DISABLED,
];
/**
 * Classname for filter pills headline
 */
export var FILTER_PILLS_HEADLINE_CLASSNAME = "".concat(FILTER_PILLS_BASE_CLASSNAME, "__headline");
/**
 * Classname for filter pills items
 */
export var FILTER_PILLS_ITEMS_CLASSNAME = "".concat(FILTER_PILLS_BASE_CLASSNAME, "__items");
/**
 * Filter pills class name
 */
export var FILTER_PILLS_OPEN_CLASSNAME = "".concat(FILTER_PILLS_ITEMS_CLASSNAME, "--open");
/**
 * Classname for filter pills item
 */
export var FILTER_PILLS_ITEM_CLASSNAME = "".concat(FILTER_PILLS_BASE_CLASSNAME, "__item");
/**
 * Classname for filter pills item input
 */
export var FILTER_PILLS_ITEM_INPUT_CLASSNAME = "".concat(FILTER_PILLS_ITEM_CLASSNAME, "-input");
/**
 * Classname for filter pills item label
 */
export var FILTER_PILLS_ITEM_LABEL_CLASSNAME = "".concat(FILTER_PILLS_ITEM_CLASSNAME, "-label");
/**
 * CLASSNAME_ITEM_ICON
 */
export var FILTER_PILLS_ITEM_ICON_CLASSNAME = "".concat(FILTER_PILLS_ITEM_CLASSNAME, "-icon");
/**
 * CLASSNAME_ITEM_ICON
 */
export var FILTER_PILLS_ITEM_TEXT_CLASSNAME = "".concat(FILTER_PILLS_ITEM_CLASSNAME, "-text");
/**
 * ClassName for showing more options button
 */
export var FILTER_PILLS_TOGGLE_MORE_CLASSNAME = "".concat(FILTER_PILLS_BASE_CLASSNAME, "__toggle--more");
/**
 * ClassName for showing less options button
 */
export var FILTER_PILLS_TOGGLE_LESS_CLASSNAME = "".concat(FILTER_PILLS_BASE_CLASSNAME, "__toggle--less");
