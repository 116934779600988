var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import renderTemplate from './_filter-pill.hbs';
import './filter-pill.scss';
import { getPropertiesFromClassName, Pattern, removeUndefinedFieldsFromObject, HandlebarRenderer, isMinTabletViewport, NO_PATTERN_BUSINESS_LOGIC, } from '@vfde-brix/ws10/core';
import { CLASSNAME_HIDDEN } from '@vfde-brix/ws10/styles';
import { FILTER_PILLS_ITEM_CLASSNAME, FILTER_PILLS_TOGGLE_MORE_CLASSNAME, ITEM_CONTAINER_ALIGN_VALUES, FILTER_PILLS_ITEM_ICON_CLASSNAME, FILTER_PILLS_OPEN_CLASSNAME, FILTER_PILLS_ITEMS_CLASSNAME, FILTER_PILLS_ITEM_INPUT_CLASSNAME, FILTER_PILLS_HEADLINE_CLASSNAME, FILTER_PILLS_ITEM_TEXT_CLASSNAME, FILTER_PILLS_TOGGLE_LESS_CLASSNAME, FILTER_PILLS_BASE_CLASSNAME, } from './Constants';
import { getSystemIconName } from '../system-icon';
import { createHeadline } from '../headline';
import { getFilterPillState } from './helpers/getFilterPillState';
/**
 * containerHeight for one or two lines of pills
 */
export var containerHeight = { oneLine: 50, twoLine: 110 };
/**
 * Filter pill component
 */
var FilterPill = /** @class */ (function (_super) {
    __extends(FilterPill, _super);
    function FilterPill() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        /**
         * Handle click
         */
        _this.handleOnClick = function (event) {
            var clickedItem = event.currentTarget;
            var showMoreButton = _this.containerElement.getElementsByClassName(FILTER_PILLS_TOGGLE_MORE_CLASSNAME)[0];
            var showLessButton = _this.containerElement.getElementsByClassName(FILTER_PILLS_TOGGLE_LESS_CLASSNAME)[0];
            // If the clicked item is a "show more" button
            if (clickedItem === showMoreButton) {
                _this.toggleOpen(true);
                showMoreButton.setAttribute('aria-expanded', 'true');
                showLessButton.setAttribute('aria-expanded', 'true');
                // show less button
                showLessButton.parentElement.classList.remove(CLASSNAME_HIDDEN);
                showLessButton.focus();
                return;
            }
            // If the clicked item is a "show less" button
            if (clickedItem === showLessButton) {
                _this.toggleOpen(false);
                showMoreButton.setAttribute('aria-expanded', 'false');
                showLessButton.setAttribute('aria-expanded', 'false');
                showMoreButton.focus();
                return;
            }
            // If the clicked item is an input (handling pill clicks)
            var filterPillInput = clickedItem;
            var isFilterPillItem = clickedItem.classList.contains(FILTER_PILLS_ITEM_INPUT_CLASSNAME);
            if (filterPillInput && !filterPillInput.disabled && isFilterPillItem) {
                var business = _this.properties.business;
                if (business.onClick) {
                    business.onClick(filterPillInput.value, event);
                }
            }
        };
        return _this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    FilterPill.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    FilterPill.prototype.writeDom = function () {
        HandlebarRenderer.render(this.containerElement, renderTemplate(this.properties));
    };
    /**
     * toggle display more/less button
     */
    FilterPill.prototype.toggleOpen = function (value) {
        var filterItems = this.containerElement.getElementsByClassName(FILTER_PILLS_ITEMS_CLASSNAME)[0];
        filterItems.classList.toggle(FILTER_PILLS_OPEN_CLASSNAME, value);
        this.setPills();
    };
    /**
     * setPills
     * shows / hides items based on: the parent container height, compared to the desired hight in closed state.
     * @property {boolean} [init] - true if initial run.
     */
    FilterPill.prototype.setPills = function (init) {
        var e_1, _a;
        var filterItems = this.containerElement.getElementsByClassName(FILTER_PILLS_ITEMS_CLASSNAME)[0];
        var showMoreButton = this.containerElement.getElementsByClassName(FILTER_PILLS_TOGGLE_MORE_CLASSNAME)[0];
        var showLessButton = this.containerElement.getElementsByClassName(FILTER_PILLS_TOGGLE_LESS_CLASSNAME)[0];
        var items = __spreadArray([], __read(this.containerElement.getElementsByClassName(FILTER_PILLS_ITEM_CLASSNAME)), false);
        var controlledPills = '';
        // first we reset all the items to display flex, to be able to measure the containers height.
        if (!init) {
            try {
                for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                    var item = items_1_1.value;
                    item.classList.remove(CLASSNAME_HIDDEN);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        if (!this.isOpen() && filterItems.offsetHeight > this.getLineHeight()) {
            showMoreButton.parentElement.classList.remove(CLASSNAME_HIDDEN);
            for (var i = items.length - 1; i >= 0; --i) {
                /* istanbul ignore else */
                if (filterItems.offsetHeight > this.getLineHeight()) {
                    items[i].classList.add(CLASSNAME_HIDDEN);
                    controlledPills = "".concat(items[i].id, " ").concat(controlledPills);
                }
            }
            var ariaControls = controlledPills.trim();
            showMoreButton.setAttribute('aria-controls', ariaControls);
            showLessButton.setAttribute('aria-controls', ariaControls);
        }
        else {
            showMoreButton.parentElement.classList.add(CLASSNAME_HIDDEN);
        }
    };
    /**
     * get isOpen state
     */
    FilterPill.prototype.isOpen = function () {
        var filterItems = this.containerElement.getElementsByClassName(FILTER_PILLS_ITEMS_CLASSNAME)[0];
        return filterItems.classList.contains(FILTER_PILLS_OPEN_CLASSNAME);
    };
    /**
     * return the max height of the container in px.
     * on mobile view we show two lines in colapsed view, on desktop we show only one line
     */
    FilterPill.prototype.getLineHeight = function () {
        /* istanbul ignore next */
        return isMinTabletViewport() ? containerHeight.oneLine : containerHeight.twoLine;
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    FilterPill.prototype.initEvents = function (isInitial) {
        var _this = this;
        var filterItems = this.containerElement.querySelectorAll(".".concat(FILTER_PILLS_ITEMS_CLASSNAME, " li"));
        filterItems.forEach(function (item) {
            item.firstElementChild.addEventListener('click', _this.handleOnClick);
        });
        if (isInitial) {
            // make it responsive to resize
            window.addEventListener('resize', function () { return _this.setPills(); });
        }
        // init
        this.setPills(true);
    };
    /**
     * Read dom
     */
    FilterPill.prototype.readDom = function (filterPillBusinessLogic) {
        var e_2, _a;
        var componentElement = this.containerElement.getElementsByClassName(FILTER_PILLS_BASE_CLASSNAME)[0];
        var optAlignment = getPropertiesFromClassName(componentElement.className, {
            alignment: __spreadArray([], __read(ITEM_CONTAINER_ALIGN_VALUES), false),
        }).alignment;
        // headline
        var headline = this.containerElement.getElementsByClassName(FILTER_PILLS_HEADLINE_CLASSNAME)[0];
        var containerHeadline = null;
        if (headline) {
            var headlineComponent = createHeadline(headline, NO_PATTERN_BUSINESS_LOGIC);
            containerHeadline = headlineComponent.getProperties();
        }
        var showMoreButton = this.containerElement.getElementsByClassName(FILTER_PILLS_TOGGLE_MORE_CLASSNAME)[0];
        var showLessButton = this.containerElement.getElementsByClassName(FILTER_PILLS_TOGGLE_LESS_CLASSNAME)[0];
        var properties = {
            listFilterPillItems: [],
            containerHeadline: containerHeadline,
            stdMoreLabel: showMoreButton.textContent.trim(),
            stdMoreAriaLabel: showMoreButton.getAttribute('aria-label'),
            stdLessLabel: showLessButton.textContent.trim(),
            stdLessAriaLabel: showLessButton.getAttribute('aria-label'),
            business: filterPillBusinessLogic,
        };
        properties.optAlignment = optAlignment;
        var filterPillElements = __spreadArray([], __read(this.containerElement.getElementsByClassName(FILTER_PILLS_ITEM_CLASSNAME)), false);
        try {
            for (var filterPillElements_1 = __values(filterPillElements), filterPillElements_1_1 = filterPillElements_1.next(); !filterPillElements_1_1.done; filterPillElements_1_1 = filterPillElements_1.next()) {
                var filterPillElement = filterPillElements_1_1.value;
                var text = filterPillElement.getElementsByClassName(FILTER_PILLS_ITEM_TEXT_CLASSNAME)[0].textContent.trim();
                var filterPillInputElement = filterPillElement.getElementsByClassName(FILTER_PILLS_ITEM_INPUT_CLASSNAME)[0];
                var state = getFilterPillState(filterPillInputElement);
                var value = filterPillInputElement.value;
                var icon = this.getIconName(filterPillElement);
                properties.listFilterPillItems.push(removeUndefinedFieldsFromObject({
                    stdPillLabel: text,
                    stdValue: value,
                    optState: state,
                    optIcon: icon,
                }));
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (filterPillElements_1_1 && !filterPillElements_1_1.done && (_a = filterPillElements_1.return)) _a.call(filterPillElements_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return removeUndefinedFieldsFromObject(properties);
    };
    /**
     * Get Icon name from DOM
     */
    FilterPill.prototype.getIconName = function (filterPill) {
        var iconSvg = filterPill.getElementsByClassName(FILTER_PILLS_ITEM_ICON_CLASSNAME)[0];
        return getSystemIconName(iconSvg);
    };
    return FilterPill;
}(Pattern));
export { FilterPill };
/**
 * Filter pill factory
 */
export var createFilterPill = function (containerElement, businessLogicOrProperties) {
    var filterPill = new FilterPill(containerElement, businessLogicOrProperties);
    filterPill.init();
    return filterPill;
};
