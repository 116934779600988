var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import './button.scss';
import renderTemplate from './_button.hbs';
import { getPropertiesFromClassName, HandlebarRenderer, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { BUTTON_COLORS, BUTTON_SIZES, BUTTON_TEXT_CLASSNAME, BUTTON_WIDTH_VARIATIONS, BUTTON_WIDTH_VARIATION_HYBRID, BUTTON_CLASSNAME_INVERSE, BUTTON_CLASSNAME_DISABLED, BUTTON_CLASSNAME_DISABLED_INVERSE, BUTTON_BASE_CLASSNAME, } from './Constants';
import { getSystemIconName } from '../system-icon';
/**
 * Button Component for all button types
 */
var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    Button.prototype.getDefaultProperties = function (newProperties) {
        if (!newProperties.optWidthVariation) {
            newProperties.optWidthVariation = BUTTON_WIDTH_VARIATION_HYBRID;
        }
        if (!newProperties.type) {
            newProperties.type = 'Button';
        }
        return newProperties;
    };
    /**
     * Render this component to the DOM
     */
    Button.prototype.writeDom = function () {
        HandlebarRenderer.render(this.containerElement, renderTemplate(this.properties));
    };
    /**
     * Init the events
     */
    Button.prototype.initEvents = function () {
        var buttonElement = this.containerElement.firstElementChild;
        // check if button is disabled or disabled-inverse to prevent any event later as there is cursor: not-allowd in scss
        var notDisable = buttonElement.className.indexOf(BUTTON_CLASSNAME_DISABLED) === -1
            && buttonElement.className.indexOf(BUTTON_CLASSNAME_DISABLED_INVERSE) === -1;
        if (this.properties.business && notDisable) {
            buttonElement.addEventListener('click', this.properties.business.onClick);
        }
        buttonElement.addEventListener('keypress', function (event) {
            if ((event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar') && notDisable) {
                event.preventDefault();
                buttonElement.click();
            }
        });
    };
    /**
     * get properties from dom
     */
    Button.prototype.readDom = function (business) {
        var buttonElement = this.containerElement.getElementsByClassName(BUTTON_BASE_CLASSNAME)[0];
        var linkHref = buttonElement.getAttribute('href');
        var optDisabled = buttonElement.getAttribute('disabled');
        var optTarget = buttonElement.getAttribute('target');
        var buttonType = buttonElement.getAttribute('type');
        var sdtTitle = buttonElement.getAttribute('title');
        var optInverse = buttonElement.classList.contains(BUTTON_CLASSNAME_INVERSE);
        var optAction = buttonElement.getAttribute('data-action');
        var optRole = buttonElement.getAttribute('role');
        var optAriaChecked = buttonElement.getAttribute('aria-checked') === 'true';
        var stdAriaLabelledBy = buttonElement.getAttribute('aria-labelledby');
        var stdAriaLabel = buttonElement.getAttribute('aria-label');
        var stdTrackingName = buttonElement.getAttribute('data-tracking-name');
        var buttonTextElement = buttonElement.getElementsByClassName(BUTTON_TEXT_CLASSNAME)[0];
        var stdContent = buttonTextElement.innerHTML.trim();
        var stdTabIndex = buttonElement.getAttribute('tabindex');
        var stdAriaDescribedBy = buttonElement.getAttribute('aria-describedby');
        var size = getPropertiesFromClassName(buttonElement.className, {
            size: BUTTON_SIZES,
        });
        var optSize = size.size;
        var propertiesFromClassName = getPropertiesFromClassName(buttonElement.className, {
            color: BUTTON_COLORS,
            optWidthVariation: BUTTON_WIDTH_VARIATIONS,
        });
        var optWidthVariation = propertiesFromClassName.optWidthVariation;
        if (!optWidthVariation) {
            optWidthVariation = BUTTON_WIDTH_VARIATION_HYBRID;
        }
        var optColor = propertiesFromClassName.color;
        var icon;
        var iconElement = buttonElement.getElementsByClassName('ws10-system-icon');
        if (iconElement.length !== 0) {
            var iconPosition = (buttonElement.classList.contains('ws10-button--icon-position-right')) ? 'right' : 'left';
            icon = {
                optPosition: iconPosition,
                stdIconName: getSystemIconName(iconElement[0]),
            };
        }
        var properties = {
            optSize: optSize,
            optColor: optColor,
            stdContent: stdContent,
            optWidthVariation: optWidthVariation,
            icon: icon,
            optInverse: optInverse,
            business: business,
        };
        properties = removeUndefinedFieldsFromObject(properties);
        if (linkHref) {
            properties.linkHref = linkHref;
        }
        else if (optRole !== null) {
            properties.optRole = optRole;
        }
        if (optTarget !== null) {
            properties.optTarget = optTarget;
        }
        if (optDisabled !== null) {
            properties.optDisabled = true;
        }
        if (optAction !== null) {
            properties.optAction = optAction;
        }
        if (buttonType === 'submit') {
            properties.optTypeSubmit = true;
        }
        if (stdTrackingName !== null) {
            properties.stdTrackingName = stdTrackingName;
        }
        if (sdtTitle !== null) {
            properties.stdTitle = sdtTitle;
        }
        if (optRole === 'checkbox') {
            properties.optAriaChecked = optAriaChecked;
            if (stdAriaLabelledBy !== null) {
                properties.stdAriaLabelledBy = stdAriaLabelledBy;
            }
        }
        if (stdAriaLabel !== null) {
            properties.stdAriaLabel = stdAriaLabel;
        }
        if (stdTabIndex !== null) {
            properties.stdTabIndex = stdTabIndex;
        }
        if (stdAriaDescribedBy !== null) {
            properties.stdAriaDescribedBy = stdAriaDescribedBy;
        }
        return properties;
    };
    /**
     * Update button without complete re-rendering
     * @param newProperties New properties
     * @param oldProperties Old properties
     */
    Button.prototype.didReceiveProps = function (newProperties, oldProperties) {
        var buttonElement = this.containerElement.firstElementChild;
        if (newProperties.stdContent !== oldProperties.stdContent) {
            var buttonTextElement = buttonElement.getElementsByClassName(BUTTON_TEXT_CLASSNAME)[0];
            buttonTextElement.innerHTML = newProperties.stdContent;
        }
        if (newProperties.stdAriaLabelledBy !== oldProperties.stdAriaLabelledBy) {
            if (newProperties.stdAriaLabelledBy) {
                buttonElement.setAttribute('aria-labelledby', newProperties.stdAriaLabelledBy);
            }
            else {
                buttonElement.removeAttribute('aria-labelledby');
            }
        }
        if (newProperties.stdAriaLabel !== oldProperties.stdAriaLabel) {
            if (newProperties.stdAriaLabel) {
                buttonElement.setAttribute('aria-label', newProperties.stdAriaLabel);
            }
            else {
                buttonElement.removeAttribute('aria-label');
            }
        }
        if (newProperties.stdTabIndex !== oldProperties.stdTabIndex) {
            if (newProperties.stdTabIndex) {
                buttonElement.setAttribute('tabindex', newProperties.stdTabIndex);
            }
            else {
                buttonElement.removeAttribute('tabindex');
            }
        }
        if (newProperties.stdAriaDescribedBy !== oldProperties.stdAriaDescribedBy) {
            if (newProperties.stdAriaDescribedBy) {
                buttonElement.setAttribute('aria-describedby', newProperties.stdAriaDescribedBy);
            }
            else {
                buttonElement.removeAttribute('aria-describedby');
            }
        }
        if (newProperties.optAriaChecked !== oldProperties.optAriaChecked) {
            buttonElement.setAttribute('aria-checked', (!!newProperties.optAriaChecked).toString());
        }
    };
    /**
     * Get button properties from DOM
     */
    Button.getPropertiesFromDom = function (containerElement) {
        return Pattern.getPropsFromDom(containerElement, createButton);
    };
    return Button;
}(Pattern));
export { Button };
/**
 * This function returns an instance of Button
 */
export var createButton = function (containerElement, businessLogicOrProperties) {
    var button = new Button(containerElement, businessLogicOrProperties);
    button.init();
    return button;
};
