import { FILTER_PILL_ITEM_STATE_CHECKED_DISABLED, FILTER_PILL_ITEM_STATE_CHECKED, FILTER_PILL_ITEM_STATE_DISABLED, } from '../Constants';
/**
 * return the state of given input element
 */
export var getFilterPillState = function (input) {
    switch (true) {
        case input.checked && input.disabled:
            return FILTER_PILL_ITEM_STATE_CHECKED_DISABLED;
        case input.checked:
            return FILTER_PILL_ITEM_STATE_CHECKED;
        case input.disabled:
            return FILTER_PILL_ITEM_STATE_DISABLED;
        default:
            return undefined;
    }
};
