var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * This is a basic component which does not have component inside component.
 * To see an example of a component inside component, check OptionTabs.ts. Option Tabs
 * has RibbonBadge used inside of it. Pay careful attention to the render function,
 * the ribbon badge import and the added dependency in the package.json of option tabs.
 */
import './form-element.scss';
import renderTemplate from './_form-element.hbs';
import { getPropertiesFromClassName, NO_PATTERN_BUSINESS_LOGIC, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { FORM_ELEMENT_BLOCK_CLASSNAME, FORM_ELEMENT_BLOCK_CLASSNAME_ERROR, FORM_ELEMENT_BLOCK_CLASSNAME_SUCCESS, FORM_ELEMENT_BLOCK_CLASSNAME_WARN, FORM_ELEMENT_BLOCK_ERROR_MESSAGE_CLASSNAME, FORM_ELEMENT_BLOCK_HELPER_TEXT_CLASSNAME, FORM_ELEMENT_BLOCK_INPUT_CLASSNAME, FORM_ELEMENT_BLOCK_LABEL_CLASSNAME, FORM_ELEMENT_BLOCK_LOADING_ANIMATION_CLASSNAME, FORM_ELEMENT_BLOCK_SHOW_WARNING, FORM_ELEMENT_BLOCK_TYPES, } from './Constants';
import { createFormTextInput, } from '@vfde-brix/ws10/form-text-input';
import { createFormTextarea, } from '@vfde-brix/ws10/form-textarea';
import { createFormSelect, } from '@vfde-brix/ws10/form-select';
import { createFormLabel, } from '@vfde-brix/ws10/form-label';
import { createLoadingAnimation, } from '@vfde-brix/ws10/loading-animation';
/**
 * Class name should always match the file name.
 * All components should inherit Component
 */
var FormElement = /** @class */ (function (_super) {
    __extends(FormElement, _super);
    /**
     * Constructor function
     */
    function FormElement(containerElement, properties) {
        if (!containerElement) {
            // It should be great to have this rather in Pattern's constructor
            throw new Error('FormElement - cannot find `containerElement` in the DOM.');
        }
        return _super.call(this, containerElement, properties) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    FormElement.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * returns always an empty object or with the necessary properties.
     * Dynamic: this.properties.label
     * Static: empty object
     */
    FormElement.prototype.getLabelProperties = function () {
        if (this.properties) {
            return __assign(__assign({}, this.properties.containerLabel), { 
                // These properties get inherited from the FormElement
                stdIdRelatedElement: this.properties.stdId, txtText: this.properties.stdLabel, optState: this.properties.optState, optDisabled: this.properties.optDisabled });
        }
        return NO_PATTERN_BUSINESS_LOGIC;
    };
    /**
     * returns always an object with the necessary properties.
     * Dynamic: properties with local event handlers
     * Static: only the local event handlers
     */
    FormElement.prototype.getInputProperties = function (inputFieldType) {
        var inputEventFunctions;
        switch (inputFieldType) {
            case 'text-input':
            case 'textarea':
            case 'select':
                inputEventFunctions = {
                    onChange: this.handleInputChange.bind(this),
                    onBlur: this.handleInputBlur.bind(this),
                    onFocus: this.handleInputFocus.bind(this),
                    onInput: this.handleInputInput.bind(this),
                    onKeyDown: this.handleInputKeyDown.bind(this),
                    onClickSystemIcon: this.handleSystemIconClick.bind(this),
                };
                break;
        }
        if (this.properties) {
            return __assign(__assign({}, this.properties.containerInput), { 
                // These properties get inherited from the FormElement
                stdName: this.properties.stdName, stdId: this.properties.stdId, optDisabled: this.properties.optDisabled, stdValue: this.properties.stdValue, optState: this.properties.optState, business: __assign(__assign({}, this.properties.business), inputEventFunctions) });
        }
        return inputEventFunctions;
    };
    FormElement.prototype.handleInputBlur = function (event, value) {
        if (this.properties.business.onBlur) {
            this.properties.business.onBlur(event, value);
        }
    };
    FormElement.prototype.handleInputChange = function (event, value) {
        if (this.properties.business.onChange) {
            this.properties.business.onChange(event, value);
        }
    };
    FormElement.prototype.handleInputFocus = function (event, value) {
        if (this.properties.business.onFocus) {
            this.properties.business.onFocus(event, value);
        }
    };
    FormElement.prototype.handleInputInput = function (event, value) {
        if (this.properties.business.onInput) {
            this.properties.business.onInput(event, value);
        }
    };
    FormElement.prototype.handleInputKeyDown = function (event, value) {
        if (this.properties.business.onKeyDown) {
            this.properties.business.onKeyDown(event, value);
        }
    };
    FormElement.prototype.handleSystemIconClick = function (event, value) {
        if (this.properties.business.onClickSystemIcon) {
            this.properties.business.onClickSystemIcon(event, value);
        }
    };
    /**
     * Set the component's state and error message based on the `stdErrorKey` property
     */
    FormElement.prototype.handleUpdateErrorKey = function (stdErrorKey) {
        var formErrors = window.brixPageOptions.formErrors;
        var stdErrorMessage = stdErrorKey ? formErrors[stdErrorKey] || formErrors.default : undefined;
        var validationStyle = this.properties.optWarnForValidation ? 'warn' : 'error';
        var optState = stdErrorKey ? validationStyle : undefined;
        this.update({ stdErrorMessage: stdErrorMessage, optState: optState }, true);
    };
    /**
     * Update error message element based on the component's `stdErrorMessage` property
     */
    FormElement.prototype.handleUpdateErrorMessage = function (stdErrorMessage) {
        if (stdErrorMessage) {
            this.errorMessageElement.innerHTML = stdErrorMessage;
            this.errorMessageElement.setAttribute('aria-label', stdErrorMessage);
        }
        else {
            this.errorMessageElement.innerHTML = '';
            this.errorMessageElement.setAttribute('aria-label', '');
        }
    };
    /**
     * Update error message element based on the component's `stdErrorMessage` property
     */
    FormElement.prototype.handleUpdateDisabled = function (isDisabled) {
        this.inputElement.disabled = isDisabled;
    };
    /**
     * Update the input component's value
     */
    FormElement.prototype.handleValueUpdate = function (stdValue) {
        this.inputElement.value = stdValue;
    };
    /**
     * Update CSS classes based on the component's `optState` property
     */
    FormElement.prototype.handleUpdateState = function (optState) {
        var _a;
        var _b;
        var stateClasses = {
            error: FORM_ELEMENT_BLOCK_CLASSNAME_ERROR,
            warn: FORM_ELEMENT_BLOCK_CLASSNAME_WARN,
            success: FORM_ELEMENT_BLOCK_CLASSNAME_SUCCESS,
        };
        (_a = this.formElementBlockElement.classList).remove.apply(_a, __spreadArray([], __read(Object.values(stateClasses)), false));
        if (optState) {
            this.formElementBlockElement.classList.add(stateClasses[optState]);
        }
        this.inputComponent.update({ optState: optState }, true);
        (_b = this.labelComponent) === null || _b === void 0 ? void 0 : _b.update({ optState: optState }, true);
    };
    FormElement.prototype.handleUpdateSystemIcon = function (optSystemIcon) {
        this.inputComponent.update({ optSystemIcon: optSystemIcon }, true);
    };
    FormElement.prototype.didReceiveProps = function (newProps, oldProperties) {
        var _a;
        var stdErrorKey = newProps.stdErrorKey, stdErrorMessage = newProps.stdErrorMessage, stdValue = newProps.stdValue, optState = newProps.optState, containerInput = newProps.containerInput, optDisabled = newProps.optDisabled;
        if (oldProperties.stdErrorKey !== stdErrorKey) {
            this.handleUpdateErrorKey(stdErrorKey);
        }
        if (oldProperties.stdErrorMessage !== stdErrorMessage) {
            this.handleUpdateErrorMessage(stdErrorMessage);
        }
        if (oldProperties.stdValue !== stdValue) {
            this.handleValueUpdate(stdValue);
        }
        if (oldProperties.optState !== optState) {
            this.handleUpdateState(optState);
        }
        if (oldProperties.optDisabled !== optDisabled) {
            this.handleUpdateDisabled(optDisabled);
        }
        // `optSystemIcon` doesn't exist in every Form Elements' interfaces
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (((_a = oldProperties.containerInput) === null || _a === void 0 ? void 0 : _a.optSystemIcon) !== (containerInput === null || containerInput === void 0 ? void 0 : containerInput.optSystemIcon)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.handleUpdateSystemIcon(containerInput.optSystemIcon);
        }
    };
    /**
     * This helper function to initializing InputComponent on writeDom and for readDom
     *
     * First it gets the properties from other helper functions
     * then call the create function with the properties.
     */
    FormElement.prototype.initDomElements = function (formElementBlockType) {
        var _a, _b;
        var labelProps = this.getLabelProperties();
        this.labelContainerElement = this.containerElement.getElementsByClassName(FORM_ELEMENT_BLOCK_LABEL_CLASSNAME)[0];
        this.inputContainerElement = this.containerElement.getElementsByClassName(FORM_ELEMENT_BLOCK_INPUT_CLASSNAME)[0];
        // Selection control does not have a label
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore (We don't want to add 'selection-control' to the FormElementBLockTypes so far, but the logic might come handy later)
        if (this.labelContainerElement && formElementBlockType !== 'selection-control') {
            this.labelComponent = createFormLabel(this.labelContainerElement, labelProps);
        }
        this.formElementBlockElement = this.containerElement.getElementsByClassName(FORM_ELEMENT_BLOCK_CLASSNAME)[0];
        this.helperTextElement = this.containerElement
            .getElementsByClassName(FORM_ELEMENT_BLOCK_HELPER_TEXT_CLASSNAME)[0];
        if ((_a = this.properties) === null || _a === void 0 ? void 0 : _a.stdHelperText) {
            this.helperTextElement.innerHTML = this.properties.stdHelperText;
            this.helperTextElement.setAttribute('aria-label', this.properties.stdHelperText);
        }
        this.errorMessageElement = this.containerElement
            .getElementsByClassName(FORM_ELEMENT_BLOCK_ERROR_MESSAGE_CLASSNAME)[0];
        if (!this.errorMessageElement) {
            this.errorMessageElement = document.createElement('span');
            this.errorMessageElement.classList.add(FORM_ELEMENT_BLOCK_ERROR_MESSAGE_CLASSNAME, 'ws10-text-smaller');
            this.formElementBlockElement.appendChild(this.errorMessageElement);
        }
        if ((_b = this.properties) === null || _b === void 0 ? void 0 : _b.stdErrorMessage) {
            this.errorMessageElement.innerHTML = this.properties.stdErrorMessage;
            this.errorMessageElement.setAttribute('aria-label', this.properties.stdErrorMessage);
        }
    };
    /**
     * This helper function to initializing InputComponent on writeDom and for readDom
     *
     * First it gets the properties from other helper functions
     * then it initialize always the FormLabelComponent and the FormTextInputComponent.
     */
    FormElement.prototype.initInputComponent = function (formElementBlockType) {
        var inputProps = this.getInputProperties(formElementBlockType);
        var inputContainerElement = this.containerElement.getElementsByClassName(FORM_ELEMENT_BLOCK_INPUT_CLASSNAME)[0];
        switch (formElementBlockType) {
            case 'text-input':
                this.inputComponent = createFormTextInput(inputContainerElement, inputProps);
                break;
            case 'textarea':
                this.inputComponent = createFormTextarea(inputContainerElement, inputProps);
                break;
            case 'select':
                this.inputComponent = createFormSelect(inputContainerElement, inputProps);
                break;
            default:
                throw new Error("Invalid form element block type '".concat(formElementBlockType, "'"));
        }
        this.inputElement = this.inputComponent.getInputElement();
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    FormElement.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.initInputComponent(this.properties.optInputFieldType);
        this.initDomElements(this.properties.optInputFieldType);
        this.mountLoadingAnimation();
    };
    /**
     * Init Events
     * (The input component also gets the business logic and attaches its own events, and this means that the validators
     * are running both here and there. This is not the most elegant way, but this should be fixed separately.)
     */
    FormElement.prototype.initEvents = function () {
        var _this = this;
        var input = this.inputElement;
        input.addEventListener('keydown', function () {
            _this.update({ optState: undefined, stdErrorKey: undefined }, true);
        });
    };
    /**
     * Reads which type of input component is rendered
     */
    FormElement.prototype.readInputTypeFromDom = function () {
        var formElementBlock = this.containerElement.getElementsByClassName(FORM_ELEMENT_BLOCK_CLASSNAME)[0];
        var className = formElementBlock.className;
        var optInputFieldType = getPropertiesFromClassName(className, {
            optInputFieldType: FORM_ELEMENT_BLOCK_TYPES,
        }).optInputFieldType;
        return optInputFieldType;
    };
    /**
     * This is an abstract function so you have to implement it.
     *
     * If the html comes from the CMS, the component needs a way to know what the properties are,
     * so this gets the properties from the DOM elements
     *
     * You normally do not require this when you do not need to perform an update
     * Make this function only return undefined if you do not need properties.
     */
    FormElement.prototype.readDom = function (formElementBlockBusinessLogic) {
        var _a, _b;
        var inputFieldType = this.readInputTypeFromDom();
        this.initDomElements(inputFieldType);
        this.initInputComponent(inputFieldType);
        this.mountLoadingAnimation();
        var labelProperties = ((_a = this.labelComponent) === null || _a === void 0 ? void 0 : _a.getProperties()) || undefined;
        var inputProperties = ((_b = this.inputComponent) === null || _b === void 0 ? void 0 : _b.getProperties()) || undefined;
        // labelRestProperties: FormElementLabelProperties
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, max-len
        var _c = labelProperties || {}, stdIdRelatedElement = _c.stdIdRelatedElement, stdLabel = _c.txtText, inputOptState = _c.optState, inputOptDisabled = _c.optDisabled, labelRestProperties = __rest(_c, ["stdIdRelatedElement", "txtText", "optState", "optDisabled"]);
        // inputRestProperties: FormElementInputVariation
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        var stdName = inputProperties.stdName, stdId = inputProperties.stdId, optDisabled = inputProperties.optDisabled, stdValue = inputProperties.stdValue, optState = inputProperties.optState, business = inputProperties.business, inputRestProperties = __rest(inputProperties, ["stdName", "stdId", "optDisabled", "stdValue", "optState", "business"]);
        var helperTextElement = this.containerElement.getElementsByClassName(FORM_ELEMENT_BLOCK_HELPER_TEXT_CLASSNAME)[0];
        var stdHelperText = helperTextElement ? helperTextElement.innerHTML.trim() : '';
        var errorMessageElement = this.containerElement.getElementsByClassName(FORM_ELEMENT_BLOCK_ERROR_MESSAGE_CLASSNAME)[0];
        var stdErrorMessage = errorMessageElement ? errorMessageElement.innerHTML.trim() : '';
        var optWarnForValidation = this.formElementBlockElement.classList.contains(FORM_ELEMENT_BLOCK_SHOW_WARNING);
        var optWithLoadingAnimation = !!this.formElementBlockElement
            .getElementsByClassName(FORM_ELEMENT_BLOCK_LOADING_ANIMATION_CLASSNAME)[0];
        var properties = {
            stdId: stdId,
            stdName: stdName,
            stdValue: stdValue,
            stdLabel: stdLabel,
            stdHelperText: stdHelperText,
            stdErrorMessage: stdErrorMessage,
            optInputFieldType: inputFieldType,
            optDisabled: optDisabled,
            optState: optState,
            optWarnForValidation: optWarnForValidation,
            optWithLoadingAnimation: optWithLoadingAnimation,
            containerInput: inputRestProperties,
            containerLabel: labelRestProperties,
            business: formElementBlockBusinessLogic,
        };
        return removeUndefinedFieldsFromObject(properties);
    };
    FormElement.prototype.mountLoadingAnimation = function () {
        var loadingAnimationContainer = this.containerElement
            .getElementsByClassName(FORM_ELEMENT_BLOCK_LOADING_ANIMATION_CLASSNAME)[0];
        var loadingAnimation = null;
        if (loadingAnimationContainer) {
            loadingAnimation = createLoadingAnimation(loadingAnimationContainer, NO_PATTERN_BUSINESS_LOGIC);
        }
        this.loadingAnimation = loadingAnimation;
    };
    /**
     * Toggles the visibility of the loading animation
     * @param showOrHide True to show, false to hide
     */
    FormElement.prototype.toggleLoadingAnimation = function (showOrHide) {
        if (!this.loadingAnimation) {
            throw new Error('Component was rendered without loading animation');
        }
        if (showOrHide) {
            this.loadingAnimation.show();
        }
        else {
            this.loadingAnimation.hide();
        }
    };
    /**
     * Give focus to the input element
     */
    FormElement.prototype.focusInput = function () {
        if (this.inputElement) {
            this.inputElement.focus();
        }
    };
    return FormElement;
}(Pattern));
export { FormElement };
/**
 * All components should have a factory function to create the component
 * This function returns an instance of FormElement
 */
export var createFormElement = function (containerElement, businessLogicOrProperties) {
    var formElement = new FormElement(containerElement, businessLogicOrProperties);
    formElement.init();
    return formElement;
};
