var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-filter-pill--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optAlignment") || (depth0 != null ? lookupProperty(depth0,"optAlignment") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optAlignment","hash":{},"data":data,"loc":{"start":{"line":1,"column":70},"end":{"line":1,"column":86}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <legend class=\"ws10-filter-pill__headline\">\n"
    + ((stack1 = container.invokePartial(require("../headline/_headline.hbs"),(depth0 != null ? lookupProperty(depth0,"containerHeadline") : depth0),{"name":"_headline","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </legend>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./_filter-pill-item.hbs"),blockParams[0][0],{"name":"_filter-pill-item","hash":{"index":blockParams[0][1]},"data":data,"blockParams":blockParams,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdMoreAriaLabel") || (depth0 != null ? lookupProperty(depth0,"stdMoreAriaLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdMoreAriaLabel","hash":{},"data":data,"loc":{"start":{"line":13,"column":160},"end":{"line":13,"column":180}}}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdMoreLabel") || (depth0 != null ? lookupProperty(depth0,"stdMoreLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdMoreLabel","hash":{},"data":data,"loc":{"start":{"line":13,"column":188},"end":{"line":13,"column":204}}}) : helper)));
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdLessAriaLabel") || (depth0 != null ? lookupProperty(depth0,"stdLessAriaLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdLessAriaLabel","hash":{},"data":data,"loc":{"start":{"line":16,"column":160},"end":{"line":16,"column":180}}}) : helper)));
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdLessLabel") || (depth0 != null ? lookupProperty(depth0,"stdLessLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdLessLabel","hash":{},"data":data,"loc":{"start":{"line":16,"column":188},"end":{"line":16,"column":204}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<search class=\"ws10-filter-pill"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optAlignment") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":1,"column":31},"end":{"line":1,"column":93}}})) != null ? stack1 : "")
    + "\">\n    <fieldset class=\"ws10-filter-pill__fieldset\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerHeadline") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "        <ul class=\"ws10-filter-pill__items\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"listFilterPillItems") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 2, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":9,"column":12},"end":{"line":11,"column":21}}})) != null ? stack1 : "")
    + "            <li class=\"ws10-filter-pill__toggle-item ws10-is-hidden\">\n                <button class=\"ws10-filter-pill__toggle ws10-filter-pill__toggle--more\" type=\"button\" aria-expanded=\"false\" aria-label=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdMoreAriaLabel") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams),"inverse":container.program(9, data, 0, blockParams),"data":data,"blockParams":blockParams,"loc":{"start":{"line":13,"column":136},"end":{"line":13,"column":211}}})) != null ? stack1 : "")
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdMoreLabel") || (depth0 != null ? lookupProperty(depth0,"stdMoreLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdMoreLabel","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":13,"column":213},"end":{"line":13,"column":229}}}) : helper)))
    + "</button>\n            </li>\n            <li class=\"ws10-filter-pill__toggle-item ws10-is-hidden\">\n                <button class=\"ws10-filter-pill__toggle ws10-filter-pill__toggle--less\" type=\"button\" aria-expanded=\"false\" aria-label=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdLessAriaLabel") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams),"inverse":container.program(13, data, 0, blockParams),"data":data,"blockParams":blockParams,"loc":{"start":{"line":16,"column":136},"end":{"line":16,"column":211}}})) != null ? stack1 : "")
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdLessLabel") || (depth0 != null ? lookupProperty(depth0,"stdLessLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdLessLabel","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":16,"column":213},"end":{"line":16,"column":229}}}) : helper)))
    + "</button>\n            </li>\n        </ul>\n    </fieldset>\n</search>";
},"usePartial":true,"useData":true,"useBlockParams":true});