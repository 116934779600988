var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { NO_PATTERN_BUSINESS_LOGIC, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { createColorSwatch, } from '../color-swatch';
import { FlagBadge } from '@vfde-brix/ws10/flag-badge';
import { HighlightBadge, } from '../highlight-badge';
import { PromoPrice, } from '../promo-price';
import { getSystemIconName } from '../system-icon';
import { CAROUSEL_CONTAINER_CLASSNAME, COLUMN_12_CLASSNAME, GRID_CONTAINER_CLASSNAME, GRID_LARGE_VIEW_CLASSNAME, GRID_SMALL_VIEW_CLASSNAME, PRODUCT_CARD_BASE_CLASSNAME, PRODUCT_CARD_COLOR_SWATCH_CLASSNAME, PRODUCT_CARD_CONTENT_BORDER_CLASSNAME, PRODUCT_CARD_CORNER_ICON_CLASSNAME, PRODUCT_CARD_DESCRIPTION_CLASSNAME, PRODUCT_CARD_FLAG_BADGE_CONTAINER_CLASSNAME, PRODUCT_CARD_HEADLINE_CLASSNAME, PRODUCT_CARD_HIGHLIGHT_BADGE_CLASSNAME, PRODUCT_CARD_IMAGE_CLASSNAME, PRODUCT_CARD_ITEM_CLASSNAME, PRODUCT_CARD_PRICE_CLASSNAME, PRODUCT_CARD_TEXT_ELEMENT_CLASSNAME, } from './Constants';
import './product-card.scss';
import renderTemplate from './_product-card.hbs';
/**
 * Product card
 */
var ProductCard = /** @class */ (function (_super) {
    __extends(ProductCard, _super);
    function ProductCard() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        /**
         * Check if the ProductCard rendered inside carousel
         */
        _this.isRenderedInsideCarousel = function () {
            var _a;
            var isRenderedInsideCarousel = (_a = _this.containerElement.parentElement) === null || _a === void 0 ? void 0 : _a.classList.contains(CAROUSEL_CONTAINER_CLASSNAME);
            return !!isRenderedInsideCarousel;
        };
        /**
         * Modify grid system when ProductCard rendered inside carousel
         */
        _this.modifyProductCardGridSystem = function () {
            var _a;
            if (_this.isRenderedInsideCarousel()) {
                var gridContainer = _this.containerElement.getElementsByClassName(GRID_CONTAINER_CLASSNAME)[0];
                (_a = gridContainer === null || gridContainer === void 0 ? void 0 : gridContainer.firstElementChild) === null || _a === void 0 ? void 0 : _a.classList.remove(GRID_SMALL_VIEW_CLASSNAME, GRID_LARGE_VIEW_CLASSNAME, COLUMN_12_CLASSNAME);
            }
        };
        _this.calculateMargins = function () {
            var productCardListContainer = _this.isRenderedInsideCarousel() ?
                _this.containerElement.parentElement :
                _this.containerElement.getElementsByClassName(PRODUCT_CARD_BASE_CLASSNAME)[0];
            var productCardItemElements = _this.getItemElements();
            if (productCardItemElements.length === 0) {
                return;
            }
            var cardWidth = productCardItemElements[0].getBoundingClientRect().width;
            var rowWidth = productCardListContainer.getBoundingClientRect().width;
            var cardsPerRow = Math.max(Math.floor(rowWidth / cardWidth), 1);
            var rowsNum = Math.ceil(productCardItemElements.length / cardsPerRow);
            for (var i = 0; i < rowsNum; i++) {
                var firstCardIndexInRow = i * cardsPerRow;
                var flagBadgeHeight = _this.getFlagBadgeHeight(cardsPerRow, firstCardIndexInRow);
                for (var j = 0; _this.isNotLastCardInRow(j, cardsPerRow, firstCardIndexInRow); j++) {
                    var cardElement = _this.getCurrentCard(firstCardIndexInRow, j);
                    // Istanbul is necessary to avoid mocking the heights of all the flagbadges.
                    /* istanbul ignore next */
                    cardElement.style.marginTop = (!_this.hasFlagBadge(cardElement) && flagBadgeHeight > 0) ? "".concat(flagBadgeHeight, "px") : '0';
                }
            }
        };
        _this.isNotLastCardInRow = function (j, cardsPerRow, firstCardIndexPerRow) {
            var productCardItemElements = _this.getItemElements();
            return j < cardsPerRow && (firstCardIndexPerRow + j) < productCardItemElements.length;
        };
        /**
         * handle onClick triggered by clicking on product
         */
        _this.handleOnClick = function (event) {
            event.preventDefault();
            var clickedElement = event.target;
            var selectedProduct = clickedElement.closest(".".concat(PRODUCT_CARD_ITEM_CLASSNAME));
            var productId = Number(selectedProduct.dataset.id);
            var productUrl = selectedProduct.getAttribute('href');
            _this.properties.business.onClick(event, productId, productUrl);
            return false;
        };
        return _this;
    }
    ProductCard.prototype.getFlagBadgeHeight = function (cardsPerRow, firstCardIndexInRow) {
        for (var j = 0; this.isNotLastCardInRow(j, cardsPerRow, firstCardIndexInRow); j++) {
            var cardElement = this.getCurrentCard(firstCardIndexInRow, j);
            if (this.hasFlagBadge(cardElement)) {
                return cardElement.getElementsByClassName(PRODUCT_CARD_FLAG_BADGE_CONTAINER_CLASSNAME)[0].getBoundingClientRect().height;
            }
        }
        // If none of the cards have flagbadge in the current row.
        return 0;
    };
    ProductCard.prototype.hasFlagBadge = function (cardElement) {
        return cardElement.getElementsByClassName(PRODUCT_CARD_FLAG_BADGE_CONTAINER_CLASSNAME).length > 0;
    };
    ProductCard.prototype.getCurrentCard = function (firstCardIndexPerRow, j) {
        var productCardItemElements = this.getItemElements();
        return productCardItemElements[firstCardIndexPerRow + j];
    };
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    ProductCard.prototype.getDefaultProperties = function (newProperties) {
        newProperties.productCardItems.forEach(function (productCardItem) {
            productCardItem.optWithBorder = productCardItem.optWithBorder === true;
            if (productCardItem.containerPromoPrices) {
                productCardItem.containerPromoPrices = productCardItem.containerPromoPrices
                    .map(function (containerPromoPrice) { return PromoPrice.setDefaultProps(containerPromoPrice); });
            }
        });
        return newProperties;
    };
    ProductCard.prototype.writeDom = function () {
        var _this = this;
        this.containerElement.innerHTML = renderTemplate(this.properties);
        // Init highlight badges in dynamic mode as well, to init tooltips within them
        __spreadArray([], __read(this.containerElement.getElementsByClassName(PRODUCT_CARD_ITEM_CLASSNAME)), false).forEach(function (item) { return _this.getHighlightBadgeFromDOM(item); });
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    ProductCard.prototype.initEvents = function () {
        var _this = this;
        var _a;
        var productCardItemElements = this.getItemElements();
        var hasOnClick = !!((_a = this.properties.business) === null || _a === void 0 ? void 0 : _a.onClick);
        hasOnClick && productCardItemElements.forEach(function (productCardItemElement) {
            productCardItemElement.addEventListener('click', _this.handleOnClick);
        });
        this.calculateMargins();
        this.modifyProductCardGridSystem();
        var timeout;
        window.addEventListener('resize', function () {
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                _this.calculateMargins();
            }, 500);
        });
    };
    ProductCard.prototype.readDom = function (productCardBusinessLogic) {
        var e_1, _a;
        var _b;
        var properties = {
            productCardItems: [],
            business: productCardBusinessLogic,
        };
        var productCardItemElements = this.getItemElements();
        try {
            for (var productCardItemElements_1 = __values(productCardItemElements), productCardItemElements_1_1 = productCardItemElements_1.next(); !productCardItemElements_1_1.done; productCardItemElements_1_1 = productCardItemElements_1.next()) {
                var itemElement = productCardItemElements_1_1.value;
                var containerFlagBadge = FlagBadge.getPropertiesFromDom(itemElement);
                var optWithBorder = itemElement.getElementsByClassName(PRODUCT_CARD_CONTENT_BORDER_CLASSNAME).length > 0;
                var imgProductCard = itemElement.getElementsByClassName(PRODUCT_CARD_IMAGE_CLASSNAME)[0].getAttribute('src');
                var stdImageAlt = itemElement.getElementsByClassName(PRODUCT_CARD_IMAGE_CLASSNAME)[0].getAttribute('alt');
                var stdCornerIcon = this.getCornerIconNameFromDOM(itemElement);
                var stdHeadline = itemElement.getElementsByClassName(PRODUCT_CARD_HEADLINE_CLASSNAME)[0].textContent.trim();
                var containerColorSwatch = this.getColorSwatchFromDOM(itemElement);
                var containerHighlightBadge = this.getHighlightBadgeFromDOM(itemElement);
                var containerPromoPrices = this.getPromoPricesFromDom(itemElement);
                var stdDescription = (_b = itemElement.getElementsByClassName(PRODUCT_CARD_DESCRIPTION_CLASSNAME)[0]) === null || _b === void 0 ? void 0 : _b.innerHTML.trim();
                var stdMoreInfoElement = itemElement
                    .getElementsByClassName(PRODUCT_CARD_TEXT_ELEMENT_CLASSNAME)[0]
                    .firstElementChild.innerHTML
                    .trim();
                var linkProductUrl = itemElement.href;
                var optTarget = itemElement.getAttribute('target') || undefined;
                var stdProductId = Number(itemElement.getAttribute('data-id'));
                properties.productCardItems.push(removeUndefinedFieldsFromObject({
                    containerFlagBadge: containerFlagBadge,
                    optWithBorder: optWithBorder,
                    imgProductCard: imgProductCard,
                    stdImageAlt: stdImageAlt,
                    stdCornerIcon: stdCornerIcon,
                    stdHeadline: stdHeadline,
                    containerColorSwatch: containerColorSwatch,
                    containerHighlightBadge: containerHighlightBadge,
                    containerPromoPrices: containerPromoPrices,
                    stdDescription: stdDescription,
                    stdMoreInfoElement: stdMoreInfoElement,
                    linkProductUrl: linkProductUrl,
                    optTarget: optTarget,
                    stdProductId: stdProductId,
                    business: {},
                }));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (productCardItemElements_1_1 && !productCardItemElements_1_1.done && (_a = productCardItemElements_1.return)) _a.call(productCardItemElements_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return properties;
    };
    /**
     * get corner icon name from DOM
     */
    ProductCard.prototype.getCornerIconNameFromDOM = function (productCard) {
        var iconElement = productCard.getElementsByClassName(PRODUCT_CARD_CORNER_ICON_CLASSNAME)[0];
        return iconElement ? getSystemIconName(iconElement) : undefined;
    };
    /**
     * get color swatch from DOM
     */
    ProductCard.prototype.getColorSwatchFromDOM = function (productCard) {
        var colorSwatchElement = productCard.getElementsByClassName(PRODUCT_CARD_COLOR_SWATCH_CLASSNAME)[0];
        if (colorSwatchElement) {
            var colorSwatch = createColorSwatch(productCard, NO_PATTERN_BUSINESS_LOGIC);
            return colorSwatch.getProperties();
        }
    };
    /**
     * get highlight badge from DOM
     */
    ProductCard.prototype.getHighlightBadgeFromDOM = function (productCard) {
        var _a = __read(productCard.getElementsByClassName(PRODUCT_CARD_HIGHLIGHT_BADGE_CLASSNAME), 1), highlightBadgeContainer = _a[0];
        return HighlightBadge.getPropertiesFromDom(highlightBadgeContainer);
    };
    /**
     * get promo price from DOM
     */
    ProductCard.prototype.getPromoPricesFromDom = function (productCard) {
        var e_2, _a;
        var promoPricesProperties = [];
        var promoPrices = productCard.getElementsByClassName(PRODUCT_CARD_PRICE_CLASSNAME);
        try {
            for (var promoPrices_1 = __values(promoPrices), promoPrices_1_1 = promoPrices_1.next(); !promoPrices_1_1.done; promoPrices_1_1 = promoPrices_1.next()) {
                var promoPrice = promoPrices_1_1.value;
                var promoPriceProperties = PromoPrice.getPropertiesFromDom(promoPrice);
                promoPricesProperties.push(promoPriceProperties);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (promoPrices_1_1 && !promoPrices_1_1.done && (_a = promoPrices_1.return)) _a.call(promoPrices_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return promoPricesProperties.length ? promoPricesProperties : undefined;
    };
    ProductCard.prototype.getItemElements = function () {
        if (this.isRenderedInsideCarousel() && this.containerElement.parentElement) {
            return __spreadArray([], __read(this.containerElement.parentElement.getElementsByClassName(PRODUCT_CARD_ITEM_CLASSNAME)), false);
        }
        return __spreadArray([], __read(this.containerElement.getElementsByClassName(PRODUCT_CARD_ITEM_CLASSNAME)), false);
    };
    return ProductCard;
}(Pattern));
export { ProductCard };
/**
 * This function returns an instance of ProductCard
 */
export var createProductCard = function (containerElement, businessLogicOrProperties) {
    var productCard = new ProductCard(containerElement, businessLogicOrProperties);
    productCard.init();
    return productCard;
};
